import React from "react";
import './style.scss';
import { Image, Button } from "react-bootstrap";
import Lottie from "react-lottie";
import Banner_Bottom from "../../../lotties/Banner_Bottom";

const Footer_Section = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Banner_Bottom,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="footer-section">
      <div className="footer-section-container container">
        <div className="come-dance-devil-background">
          <div className="row align-items-center">
            <div className="col-lg-7 mt-md-5 mb-2 pt-sm-3 my-lg-0 pt-lg-0 text-center text-lg-start">
              <div className="come-dance-devil-text-part">
                <h3 className="devilflip-h3-heading mb-2 mb-md-3">Come Dance With The Devil</h3>
                <p className="mobile-friendly-devilflip-paragraph mb-3 mb-sm-4 pb-sm-3">Stay up to date by joining our Official Communities</p>
                <div className="devilflip-come-dance-devil-btn d-inline-block me-2 me-sm-3 come-dance-devil-1 mb-md-3 mb-lg-0 ">
                  <Button variant="outline-info">
                    <Image src="/assets/images/footer_join_chat_button.png" className="mobile-friendly-join-chat-image me-1" /> Join
                    Chat
                  </Button>
                </div>
                <div className="devilflip-come-dance-devil-btn d-inline-block come-dance-devil-2">
                  <Button variant="outline-info">
                    <Image src="/assets/images/top_section_pancake_button.png" className="mobile-friendly-coming-soon-image me-1 me-md-2" />
                    <span className="ms-1">Coming Soon</span> </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 text-center">
              <div className="footer-box-video">
                {/* <Image
                  src="/assets/images/footer.png"
                  className="come-dance-devil-image"
                /> */}
                <Lottie
                  options={defaultOptions}  
                  style={{ pointerEvents: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center mb-4 footer-devil-border justify-content-between">
          <div className="col-auto col-sm-6 col-md-auto text-center text-sm-start mb-3 mb-md-0">
            <Image src="/assets/images/logo.svg" height="60px" width="60px" className="footer-logo" />
          </div>
          <div className="col-12 col-sm-12 col-md-auto text-center order-2 order-md-1 mt-3 mt-sm-3 mt-md-0">
            <a href="#23" className="link-dark footer-link">
              Terms Of Service
            </a>
            <a href="#34" className="link-dark footer-link" style={{ marginLeft: "15px" }}>
              Privacy Policy
            </a>
          </div>
          <div className="col-auto col-sm-6 col-md-auto text-center text-md-right order-1 order-md-2 mb-3 mb-md-0">
            <div className="footer-custom-class">
              <div className="footer-follow-us-on mb-2 ">Follow us On</div>
              <div className="footer-social-wrap">
                <Button variant="link" href="#71">
                  <Image src="/assets/images/footer_link1.png" className="footer-follow-us-on-icon" />
                </Button>
                <Button variant="link" href="#72">
                  <Image src="/assets/images/footer_link2.png" className="footer-follow-us-on-icon" />
                </Button>
                <Button variant="link" href="#73">
                  <Image src="/assets/images/footer_link3.png" className="footer-follow-us-on-icon" />
                </Button>
                <Button variant="link" href="#74">
                  <Image src="/assets/images/footer_link4.png" className="footer-follow-us-on-icon" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer_Section;
