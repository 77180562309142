import React,{useState,useEffect} from "react";
import "./styles.scss";
import { Navbar, Nav, Button, Image } from "react-bootstrap";
import Lottie from "react-lottie";
import isEmpty from "lodash/isEmpty";
import Hero_M from "../../../lotties/Hero_M.json";
import Hero_D from "../../../lotties/Hero_D.json";

const _ = { isEmpty };

const Top_Section = () => {
  
  const [responsiveMenu, setresponsiveMenu] = useState("");

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: windowDimensions.width <= 768 ? Hero_M : Hero_D ,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  
  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {width};
  }

  useEffect(() => {
    
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    
  }, []); 
  

  const handleResponsiveMenu = () => {
    let cls = _.isEmpty(responsiveMenu) ? "responsive-nav-container" : "";
    setresponsiveMenu(cls);
  };
  return (
    <div className="home-background-image home-section">
      <div className="container">
        <section className="top-section home-header-section">
          <div className="top-section-container"> 
            <div className="row">
              <Navbar variant="dark" sticky="top" >
                <div className="col-6 col-lg-3 col-xl-2 header-logo">
                  <Navbar.Brand>
                    <Image
                      src="/assets/images/logo.svg"
                      height="40px"
                      width="40px"
                    />
                  </Navbar.Brand>
                </div>
                <div className={`col-6 col-lg-6 col-xl-8 text-end text-lg-center mobile-menu-section ${responsiveMenu}`}>
                {_.isEmpty(responsiveMenu) ? (
                    <Button
                      type="link"
                      className="mobile-icon mobile-menu-bar"
                      // style={{display:'none'}}
                      onClick={() => handleResponsiveMenu()}
                    >
                     <Image src="/assets/images/mobile-menu-bar.png" className="" />
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      className="mobile-icon mobile-menu-close"
                      // style={{display:'none'}}
                      onClick={() => handleResponsiveMenu()}
                    >
                      <Image src="/assets/images/close.png" className="" />
                    </Button>
                  )}
                  <Nav className="menu mobile-responsive-menu">
                    <Nav.Link href="#1">Home</Nav.Link>
                    <Nav.Link href="#2">Game</Nav.Link>
                    <Nav.Link href="#3">Docs</Nav.Link>
                    <Nav.Link href="#3">Wagyu</Nav.Link>
                    <Nav className="header-join-chat-btn-navbar">
                    <div className="header-join-chat-btn">
                      <Button variant="warning"><Image src="/assets/images/footer_join_chat_button.png" className="header-join-chat-img" /><span className="header-join-chat-text">Join Chat</span></Button>
                    </div>
                  </Nav>
                  </Nav>
                </div>
                <div className="col-6 col-lg-3 col-xl-2 text-end desktop-join-chat">
                  <Nav>
                    <div className="header-join-chat-btn">
                      <Button variant="warning"><Image src="/assets/images/footer_join_chat_button.png" className="header-join-chat-img" /><span className="header-join-chat-text">Join Chat</span></Button>
                    </div>
                  </Nav>
                </div>
              </Navbar>
            </div>
          </div>
        </section>
      </div>
      <div className="header-background-top-section">
        <div className="background-container container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-xl-6 text-center text-lg-start  pe-lg-0 pe-xl-3">
              <div className="devilFlip-background-header mt-sm-5 mt-xl-0">Welcome to DevilFlip</div>
              <div className=" top-section-header-wecolme-mobile"> <Lottie
                options={defaultOptions}  
                style={{ pointerEvents: "none" }}
                />
              </div>
              <p className="devilFlip-background-paragraph text-center text-lg-start">
                The First ever DeFi game that allows players to strategically,
                stake,defend and attack enemy stacking Pools.
              </p>
              <div className="devilFlip-background-btn-1  d-inline-block me-2  me-sm-3 me-lg-2 me-xl-3">
                <Button variant="outline-info">
                  <Image src="/assets/images/top_section_pancake_button.png" className="devilFlip-background-btn-1-image" />
                  PancakeSwap Coming Soon
                </Button>
              </div>
              <div className="devilFlip-background-btn-2 d-inline-block">
                <Button variant="outline-info">Game Coming Soon</Button>
              </div>
              <div className="devilFlip-background-logo-content mt-2 mb-5 pb-lg-0 my-xl-0">
                Exclusively on{" "}
                <span>
                  <Image src="/assets/images/top_section_binance.png" className="devilFlip-background-binance" />
                </span>
                <b>Binance Smart Chain</b>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 top-section-header-wecolme-desktop ">
              <div className="banner-img">
                <Lottie
                  options={defaultOptions}  
                  style={{ pointerEvents: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top_Section;
