import React from "react";
import './style.scss';
import { Image } from "react-bootstrap";

const OneSide_Chosen = () => {
  return (
    <section className="oneside-chosen-section devilflip-section pb-0">
      <div className="oneside-chosen container">
        <div className="row align-items-center">
          <div className="col-lg-6 text-center text-lg-start custom-col-1">

            <div className="title-img">
              <Image src="/assets/images/title/1.png" className="" />
              <Image src="/assets/images/title/2.png" className="" />
            </div>

            <p className="devilflip-paragraph mb-md-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
            </p>
          </div>
          <div className="col-lg-6 text-end mt-3 mt-sm-5 mt-lg-0 custom-col-2">
            <div className="onside-video">
              <div className="frame-dots">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <video
                loop={true}
                autoPlay={true}
                muted={true}
                playsInline={true}
                className="video-player devilflip-section-image"
              >
                <source
                  src={`${process.env.PUBLIC_URL}/assets/images/oneside_chosen.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OneSide_Chosen;
