import React from "react";
import './style.scss';
import { Card } from "react-bootstrap";
import { Image } from "react-bootstrap";

const Devilish_Tokenomics = () => {
  return (
    <section className='devilish-tokenomics-section devil-pumps-section pt-0'>
      <div className="devilish-tokenomics-container" style={{ textAlign: "center" }}>
        <div className="container pt-3 devilish-tokenomics-container">

          <div className="title-img text-center title-img-2">
            <Image src="/assets/images/title/5.png" className="" />
          </div>

          <div className="tokenomics-items">
            <div className="row">
              <div className="col-sm-12 col-lg-12 mx-md-auto devilish-tokenomics-box">
                <div className="row">
                  <div className="col-lg-4 mb-4 mb-sm-5 mb-lg-0">
                    <Card className="devilflip-Tokenomics-card-box">
                      <div className="icon">
                      <Card.Img
                        variant="top"
                        src="/assets/images/devilish_1.png"
                        className="devilflip-Tokenomics-card-image"
                      />
                      </div>
                      <Card.Body>
                        <Card.Title><h5 className="devilflip-Tokenomics-card-heading">2.5% Liquidity Tax</h5></Card.Title>
                        <Card.Text className="devilflip-Tokenomics-card-paragraph">
                          Each non-whitelisted transaction takes a 10% transaction fee
                          and adds a directly to pancakeswap Liquidity.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-lg-4 mb-4 mb-sm-5 mb-lg-0">
                    <Card className="devilflip-Tokenomics-card-box">
                      <div className="icon">
                      <Card.Img
                        variant="top"
                        src="/assets/images/devilish_2.png"
                        className="devilflip-Tokenomics-card-image"
                      />
                      </div>
                      <Card.Body>
                        <Card.Title> <h5 className="devilflip-Tokenomics-card-heading">7.5% Master Wallet Tax</h5> </Card.Title>
                        <Card.Text className="devilflip-Tokenomics-card-paragraph">
                          Stacking in any team of the game burns 100% of tokens,creating
                          significant deflation.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-lg-4 mb-0 mb-sm-0 mb-lg-0">
                    <Card className="devilflip-Tokenomics-card-box">
                      <div className="icon">
                      <Card.Img
                        variant="top"
                        src="/assets/images/devilish_3.png"
                        className="devilflip-Tokenomics-card-image"
                      />
                      </div>
                      <Card.Body>
                        <Card.Title> <h5 className="devilflip-Tokenomics-card-heading">Buy/Sell Freeze</h5> </Card.Title>
                        <Card.Text className="devilflip-Tokenomics-card-paragraph">
                          In return for your burned stake in the game,you own a
                          permanent share of your teams earnings- forever!
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </section>
  );
};

export default Devilish_Tokenomics;
