import React from "react";
import './style.scss';
import { Image } from "react-bootstrap";

const Angle_Pumps = () => {
  return (
    <section className="angle-pumps angle-pumps-section devilflip-section pb-0 pt-5">
      <div className="angle-pumps container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-2 order-lg-1">
            <video
              loop={true}
              autoPlay={true}
              muted={true}
              playsInline={true}
              className="video-player devilflip-section-image"
            >
              <source
                src={`${process.env.PUBLIC_URL}/assets/images/angel_pumps.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="col-lg-6 text-center text-lg-start order-1 order-lg-2  mt-0 mt-md-5 mt-lg-0">
            <div className="devilflip-angle-pumps">
              <div className="title-img">
                <Image src="/assets/images/title/3.png" className="" />
              </div>
              <p className="devilflip-paragraph w-100 mb-md-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Angle_Pumps;
