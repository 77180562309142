import React from "react";
import Top_Section from "./top_section/index";
import OneSide_Chosen from "./oneside_chosen/index";
import Angle_Pumps from "./angel_pumps";
import Devil_Pumps from "./devil_pumps";
import Devilish_Tokenomics from "./devilish_tokenomics";
import Mobile_Friendly from "./mobile_friendly";
import Footer_Section from "./footer_section";

const Home = () => {
  return ( 
    <div className="">
      <Top_Section />
      <OneSide_Chosen />
      <Angle_Pumps />
      <Devil_Pumps />
      <Devilish_Tokenomics />
      <Mobile_Friendly />
      <Footer_Section />
    </div>
  );
};

export default Home;
