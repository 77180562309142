import React from "react";
import './App.scss';
import { Route } from "react-router";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Nav from './components/Nav/index';

const App = () => {
  return (
    <div className="">
      <Route component={Nav} />
    </div>
  );
};

export default App;
