import React from "react";
import { Route, Redirect } from "react-router";
import Home from "../Home/Home";

const Nav = () => {
  return (
    <div>
      <Route path="/" component={Home}></Route>
    </div>
  );
};

export default Nav;
